var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { full: "" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "back" }, [
          _c("img", {
            attrs: {
              src: require("../../../assets/img/album/nav_icon_back.svg"),
              alt: "",
            },
            on: { click: _vm.back },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$route.query.name))]),
        ]),
        _c("img", {
          staticClass: "upload",
          attrs: {
            src: require("../../../assets/img/album/myupload.svg"),
            alt: "",
          },
          on: {
            click: function ($event) {
              _vm.show = true
            },
          },
        }),
      ]),
      _vm.list && _vm.list.length
        ? _c(
            "div",
            { staticClass: "photos" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  staticClass: "photo",
                  on: {
                    click: function ($event) {
                      return _vm.previewImage(item.fileTxId)
                    },
                  },
                },
                [
                  _c("van-image", {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      src: _vm._f("fileUrl")(item.fileTxId),
                      fit: "cover",
                      alt: "",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c("div", { staticClass: "nodata" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/album/album_default.svg"),
                alt: "",
              },
            }),
            _c(
              "div",
              {
                staticClass: "import_btn",
                on: {
                  click: function ($event) {
                    _vm.show = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.uploadOrImportTips")))]
            ),
          ]),
      _c(
        "van-popup",
        {
          staticClass: "import_popup",
          attrs: { position: "bottom", round: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("lang.uploadOrImport"))),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "van-uploader",
                {
                  staticClass: "photo-upload",
                  attrs: { "after-read": _vm.afterRead, multiple: "" },
                },
                [
                  _c("div", { staticStyle: { "padding-left": "0" } }, [
                    _vm._v(_vm._s(_vm.$t("lang.fromLocal"))),
                  ]),
                ]
              ),
              _c("div", { on: { click: _vm.chainUpload } }, [
                _vm._v(_vm._s(_vm.$t("lang.onChain"))),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "cancel",
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang.cancel")))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }