<template>
  <layout full>
    <div class="header">
      <div class="back">
        <img src="../../../assets/img/album/nav_icon_back.svg" alt="" @click="back">
        <span>{{$route.query.name}}</span>
      </div>
      <img src="../../../assets/img/album/myupload.svg" alt="" @click="show=true" class="upload">
    </div>
    <div class="photos" v-if="list && list.length">
      <div class="photo" v-for="(item, index) in list" @click="previewImage(item.fileTxId)">
        <van-image :src="item.fileTxId | fileUrl" fit="cover" alt="" width="100%" height="100%" style="cursor: pointer" />
      </div>
    </div>
    <div v-else class="nodata">
      <img src="../../../assets/img/album/album_default.svg" alt="">
      <div class="import_btn" @click="show=true">{{ $t('lang.uploadOrImportTips') }}</div>
    </div>
    <van-popup v-model="show" position="bottom" round class="import_popup">
      <div class="title">{{ $t('lang.uploadOrImport') }}</div>
      <div class="content">
        <van-uploader class="photo-upload" :after-read="afterRead" multiple>
          <div style="padding-left: 0">{{ $t('lang.fromLocal') }}</div>
        </van-uploader>
        <div @click="chainUpload">{{ $t('lang.onChain') }}</div>
      </div>
      <div class="cancel" @click="show=false">{{ $t('lang.cancel') }}</div>
    </van-popup>
  </layout>
</template>

<script>
import { Uploader, Popup } from 'vant'
import {getMyMetaPhotoItemTxList} from "api/api";
import {fileToMetaFile, setAttachments} from "sdk";
import common from '@/utils/common'

export default {
  name: "AlbumDetail",
  data() {
    return {
      attachments: [],
      list: [],
      show: false
    }
  },
  components: {
    [Uploader.name]: Uploader,
    [Popup.name]: Popup
  },
  mounted() {
    this.getMyMetaPhotoItemTxList()
  },
  filters: {
    fileUrl(tx) {
      if (tx.indexOf("metafile://") != -1) {
        return `${process.env.VUE_APP_AppImgApi}/metafile/${tx.replace("metafile://", "compress640/").toLowerCase()}`
      } else {
        return `${process.env.VUE_APP_AppImgApi}/metafile/compress640/${tx}`
      }
    }
  },
  methods: {
    getMyMetaPhotoItemTxList() {
      let params = {
        page: 1,
        pageSize: 15,
        timestamp: 0,
        metaId: this.$route.query.metaId,
        folderMetanetId: this.$route.query.metanetId
      }
      getMyMetaPhotoItemTxList(params).then(res => {
        this.list = res.data.results.items
      })
    },
    chainUpload() {
      this.$router.push({
        path: '/albumChooseup',
        query: {
          name: this.$route.query.name,
          metanetId: this.$route.query.metanetId
        }
      })
    },
    back() {
      this.$router.back()
    },
    previewImage(txId) {
      if (txId.indexOf("metafile://") != -1) { // 从本地上传，带有metafile://
        txId = txId.replace("metafile://", "")
      }
      this.$router.push({
        path: '/photo/view',
        query: {
          address: txId
        }
      })
    },
    async afterRead(file) {
      let files = file instanceof Array ? file : [file]
      let filesTemp = []
      for (let i =0; i< files.length; i++) {
        const file = await fileToMetaFile(files[i].file)
        filesTemp.push(file)
      }

      let params = {
        "folderID": this.$route.query.metanetId, // 所属的文件夹metanetID
        "option": '', // 0添加 1删除
        "txs": filesTemp,
        "createTime": +new Date()
      }

      const { data, attachments} = await setAttachments(params,[
        { name: 'txs', encrypt: '0'}
      ])

      const txData = common.getTxData({
        nodeName: 'metaphotoItem',
        brfcId: 'c31caffd28ab',
        path: '/Protocols/metaphotoItem',
        data: JSON.stringify(data),
        attachments: attachments,
        payTo: [
          {
            amount: 500,
            address: process.env.VUE_APP_PlatformAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.$toast(this.$t('lang.success'))
        this.show = false
        this.getMyMetaPhotoItemTxList()
      }, (err) => {

      })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 48px;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .back {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 2px;
      font-size: 14px;
    }
  }
  .upload {
    cursor: pointer;
  }
}
.photos {
  display: flex;
  //justify-content: space-between;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 10px 10px 10px 20px;
  //margin-right: -10px;
  .photo {
    width: 105px;
    height: 105px;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    //&:nth-child(3n) {
    //  margin-right: 0;
    //}
    /deep/ img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .import_btn {
    margin-top: 15px;
    padding: 9px 10px;
    background: #617fff;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
}
.import_popup {
  .title {
    margin: 30px 0 25px;
    color: #313131;
    font-size: 20px;
    text-align: center;
  }
  .content {
    margin-top: 20px;
    div {
      height: 60px;
      line-height: 60px;
      background: #fff;
      padding-left: 20px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .cancel {
    margin-top: 20px;
    line-height: 60px;
    height: 60px;
    background: #fff;
    text-align: center;

    //width: 335px;
    height: 50px;
    background: #edeff2;
    border-radius: 25px;
    width: 100%;
  }
}
.photo-upload {
  display: block;
  /deep/.van-uploader__wrapper {
    display: block;
  }
}
</style>